import React, { useContext, useEffect, useState } from 'react';
import './allsportdata.css';
import { Link, useLocation } from 'react-router-dom';
import { PopupContext } from '../LoginPopup/LoginPopupContext';

function Allsportdata({ matchData,selectedTypes }) {
    const [updateMatchOdds, setStateValue] = useState();
    const [types, setTypes] = useState();
    const { exchangeData } = useContext(PopupContext);
    useEffect(() => {
        setStateValue(matchData);
        setTypes(selectedTypes);
        // if (Array.isArray(exchangeData) && exchangeData.length > 0) {
        //     exchangeData.forEach((exchnageItem, exchnageIndex) => {
        //         console.log(exchnageItem.eventName, '---', exchnageItem.sportId);
        //     });
        // }
      }, [matchData,selectedTypes,exchangeData]);

    const validation = (price) => {
        if(price &&
            price !== '-' &&
            price !== 0 &&
            price <= 1000.00){
                return true
            }else{
                return false
            }
    }

    const dateFormet = (date)=>{
        return new Date(date).toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        })
    }

    const renderData = (gameItem) => {
        let lastSeries = null;
        
        return gameItem.matchData.map((matchItem, matchindex) => {
            const currentCompetitionName = matchItem.match.competitionName;
            const shouldShow = lastSeries !== currentCompetitionName;
            lastSeries = currentCompetitionName;

     // Find matching exchange item
     let updatedExchangeItem = null;
     if (Array.isArray(exchangeData) && exchangeData.length > 0) {
         updatedExchangeItem = exchangeData.find(
             (exchangeItem) => exchangeItem.eventId === matchItem.match.eventId
         );
     }

     // Update matchItem if a match is found
     if (updatedExchangeItem) {
            matchItem.match.eventName = updatedExchangeItem.eventName;
            matchItem.match.sportId = updatedExchangeItem.sportId;
            matchItem.match.status = updatedExchangeItem.status;
            if (Array.isArray(matchItem.matchodd[0].runners)) {
                const updatedRunners = updatedExchangeItem.markets.matchOdds[0]?.runners;
                
                if (updatedRunners) {
                    if (matchItem.matchodd[0].runners.length === 1) {
                        // Check if updatedExchangeItem.matchodd.runners[0] is defined
                        if (updatedRunners[0]?.layPrices && updatedRunners[0]?.backPrices) {
                            matchItem.matchodd[0].runners[0].layPrices = updatedRunners[0].layPrices;
                            matchItem.matchodd[0].runners[0].backPrices = updatedRunners[0].backPrices;
                        }
                    } else if (matchItem.matchodd[0].runners.length === 2) {
                        // Check if updatedExchangeItem.matchodd.runners[0] and [1] are defined
                        if (updatedRunners[0]?.layPrices && updatedRunners[0]?.backPrices) {
                            matchItem.matchodd[0].runners[0].layPrices = updatedRunners[0].layPrices;
                            matchItem.matchodd[0].runners[0].backPrices = updatedRunners[0].backPrices;
                        }
                        if (updatedRunners[1]?.layPrices && updatedRunners[1]?.backPrices) {
                            matchItem.matchodd[0].runners[1].layPrices = updatedRunners[1].layPrices;
                            matchItem.matchodd[0].runners[1].backPrices = updatedRunners[1].backPrices;
                        }
                    } else if (matchItem.matchodd[0].runners.length === 3) {
                        // Check if updatedExchangeItem.matchodd.runners[0], [1], and [2] are defined
                        if (updatedRunners[0]?.layPrices && updatedRunners[0]?.backPrices) {
                            matchItem.matchodd[0].runners[0].layPrices = updatedRunners[0].layPrices;
                            matchItem.matchodd[0].runners[0].backPrices = updatedRunners[0].backPrices;
                        }
                        if (updatedRunners[1]?.layPrices && updatedRunners[1]?.backPrices) {
                            matchItem.matchodd[0].runners[1].layPrices = updatedRunners[1].layPrices;
                            matchItem.matchodd[0].runners[1].backPrices = updatedRunners[1].backPrices;
                        }
                        if (updatedRunners[2]?.layPrices && updatedRunners[2]?.backPrices) {
                            matchItem.matchodd[0].runners[2].layPrices = updatedRunners[2].layPrices;
                            matchItem.matchodd[0].runners[2].backPrices = updatedRunners[2].backPrices;
                        }
                    }
                } else {
                    console.error('updatedExchangeItem.matchodd.runners is undefined or empty');
                }
            } else {
                console.error('matchItem.matchodd[0].runners is not an array');
            }
            if(matchItem.match.eventId == "33847636"){
                
            }
            console.log(matchItem.match.status,'~~~~~~~',matchItem);
       
         // Update other properties as needed
     }
            
            return ( 
                <>
                {shouldShow && types === 'competitions' ? <div className='series-title'>{matchItem.match.competitionName}</div>:''}
                    <Link to={`/exchange_inPlay/${matchItem.match.eventId}`} className='match-info-row' key={matchindex}>
                        <div className='match-update'>
                            {matchItem.match.status === 'IN_PLAY' ? <div className="short-score">
                                <div className="block left">
                                    <span>In Play</span>
                                </div>
                            </div> : ''}
                            {matchItem.match.status === 'IN_PLAY' ? <div className='match-live-status'>Live</div> : ''}
                            <div className='match-date'>
                            {dateFormet(matchItem?.match?.openDate)}
                            </div>

                        </div>
                        <div className='match-name'>
                            <strong>{matchItem.match.eventName}</strong>
                            {matchItem.match.status === 'IN_PLAY' ? <div className='match-live-status'>Live</div> : ''}

                            <div className='match-stream'>
                                {matchItem.match.status === 'IN_PLAY' ? <div className='box'> <span><i className="fa-solid fa-tv"></i></span> </div> : ''}
                                {matchItem?.MatchOddCount > 0 ? <div className='box'> <span className='blue-bg'>MO</span> </div> : ''}
                                {matchItem?.BMcount > 0 ? <div className='box'> <span className='blue-bg'>BM</span> </div> : ''}
                                {matchItem?.FANCYcount > 0 ? <div className='box'> <span className='blue-bg'>F</span> </div> : ''}
                                {matchItem?.TossCount > 0 ? <div className='box'> <span className='blue-bg'>T</span> </div> : ''}
                            </div>
                        </div>
                        <div className='match-odds'>
                            <div className='box'>
                                <span className='blue'>
                                {validation(matchItem.matchodd[0]?.runners[0]?.backPrices[0]?.price) ?
                                    matchItem.matchodd[0].runners[0]?.backPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                                <span className='pink'>

                                {validation(matchItem.matchodd[0]?.runners[0]?.layPrices[0]?.price) ?
                                     matchItem.matchodd[0]?.runners[0]?.layPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                            </div>
                            {matchItem.matchodd[0]?.runners.length === 3 ?
                            <div className='box'>
                            <span className='blue'>
                            {validation(matchItem.matchodd[0]?.runners[2]?.backPrices[0]?.price) ?
                                 matchItem.matchodd[0]?.runners[2]?.backPrices[0]?.price :
                                  <i className='fa fa-lock'></i>}</span>
                            <span className='pink'>

                            {validation(matchItem.matchodd[0]?.runners[2]?.layPrices[0]?.price) ?
                                 matchItem.matchodd[0]?.runners[2]?.layPrices[0]?.price :
                                  <i className='fa fa-lock'></i>}</span>
                        </div> :
                            <div className='box'>
                                <span className='blue'><i className='fa fa-lock'></i></span>
                                <span className='pink'><i className='fa fa-lock'></i></span>
                            </div>}
                            <div className='box'>
                                <span className='blue'>
                                {validation(matchItem.matchodd[0]?.runners[1]?.backPrices[0]?.price) ?
                                     matchItem.matchodd[0]?.runners[1]?.backPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                                <span className='pink'>

                                {validation(matchItem.matchodd[0]?.runners[1]?.layPrices[0]?.price) ?
                                     matchItem.matchodd[0]?.runners[1]?.layPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                            </div>
                        </div>
                    </Link>
            </>
            );
        })
    }


    return (
        <>
            {updateMatchOdds?.game?.map((gameItem, index) => {
                return (
                    <>
                    {gameItem.matchData && gameItem.matchData.length > 0 ?
                    <div className='sport-all-data' key={index}>
                        <div className='match-wrapper'>
                            <div className='heading'>
                                <div className='heading-text sport-name'>
                                    {gameItem.game}
                                </div>
                                <div className='odds-heading'>
                                    <div className='heading-text box'>1</div>
                                    <div className='heading-text box'>X</div>
                                    <div className='heading-text box'>2</div>
                                </div>
                            </div>
                            {gameItem.matchData && gameItem.matchData.length > 0 ?
                            <div className='match-data'>
                                <div className='match-list'>
                                    {renderData(gameItem)}  
                                </div>
                            </div>
                             : <p className='no-data'>Not available {gameItem.game} matches</p>}
                        </div>
                    </div> : ''}</>
                )
            })}

        </>
    )

}

export default Allsportdata;



