import React, { useContext, useEffect, useRef, useState } from 'react'
import './header.css'
import logo from '../../../assets/images/Royal777.png'
import userProfile from '../../../assets/images/user.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import Popup from '../popup/Popup'
import { UserContext } from '../../../UserContext'
import { PopupContext } from '../../LoginPopup/LoginPopupContext'
import { Modal } from 'react-bootstrap';
import OneClickPopups from '../../menudropdoenpopup/OneClickPopup'
import ChangePasswordPopup from '../../menudropdoenpopup/ChangePasswordPopup'
import StackChnage from '../../menudropdoenpopup/StackChnage'

function Header() {
  const { sessionData } = useContext(UserContext);
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const initialized = useRef(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [logout, setLogout] = useState(false);
  const [menuResponse, setMenuResponse] = useState();
  const [domainName, setDomainName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
  const [notificationData, setNotificationData] = useState();
  const { openPopup , loggedIn, isLoggedOut,openDialogSet,clearDialog } = useContext(PopupContext);
  const [notificationModal, setNotificationModal] = useState(false);
  const [OneClickPopup, setOneClickPopup] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [stackChange, setStackChange] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [totalExposure, setTotalExposure] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [isPasswordChange, setIsPasswordChange] = useState(() => {
    const storedValue = sessionStorage.getItem('passwordchanged');
    return storedValue === "true";
  });

  const callGameReport = () => {
    if(isLoggedIn || loggedIn){
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/logInCheck`, requestOptions)
        .then(async (response) => {
          let paresData = await response.json();
          if(paresData.isLogout){
            logoutHandle(false);
          }
          // console.log("logInCheck - ", paresData);
        })
        .catch((error) => {
          console.log(error);
        });
      }
  };

  const callWallet = () => {
    if(isLoggedIn || loggedIn){
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getexposure`, requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
      setTotalExposure(paresData.data.totalExposure)
      setAvailableBalance(paresData.data.availableBalance)
      // console.log("getexposure - ", paresData);
    })
    .catch((error) => {
      console.log(error);
    });
  }
  }

  const handleClose = () => {clearDialog(); setNotificationModal(false); setOneClickPopup(false); setChangePassword(false); setStackChange(false);}
  const handleShow = (type) => {
    if(type === 'notification'){
      setNotificationModal(true);
      setOneClickPopup(false);
      setChangePassword(false);
      setStackChange(false);
    }else if(type === 'one_click'){
      openDialogSet('one_click');
      setNotificationModal(false);
      setOneClickPopup(true);
      setChangePassword(false);
      setStackChange(false);
    }else if(type === 'change_password'){
      setChangePassword(true)
      setNotificationModal(false);
      setOneClickPopup(false);
      setStackChange(false);
    }else if(type === 'stack_change'){
      setChangePassword(false)
      setNotificationModal(false);
      setOneClickPopup(false);
      setStackChange(true);
    }else{
      setChangePassword(false);
      setNotificationModal(false);
      setOneClickPopup(false);
      setStackChange(false);
    }
  }

  const callMenuAPI = () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      whiteLabelName: "jerambro.com"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/getMenuData`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setMenuResponse(paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const hostname = window.location.hostname;
      setDomainName(hostname);
    callMenuAPI();
    callNotificationAPI();
    }
  }, [loggedIn])

  useEffect(() => {
    if(isLoggedIn || loggedIn){
      setIsPasswordChange(sessionStorage.getItem('passwordchanged') === "true")
      if(sessionStorage.getItem('passwordchanged') === "true"){
        setChangePassword(true)
        console.log("password Not Updated")
      }else{
        console.log("Password Updated")
      }
    }
  }, [isPasswordChange, isLoggedIn,loggedIn])

  const logoutHandle = (flag) => {
    const token = sessionStorage.getItem('token');
    sessionStorage.clear();
    setDropdown(false);
    setIsLoggedIn(false);
    if(flag){
      setLogout(true);
      navigate("/");
    }


    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}` );
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
  fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/logOut`, requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
  isLoggedOut();
    const logoutMsgTimer = setTimeout(() => {
      setLogout(false);

    }, 2000);
    return () => {
      clearTimeout(logoutMsgTimer);
    };
  };

  const dropdownHandle = () => {
    setDropdown(!dropdown);
  }


  const handleMenu = (item) => {
    if(item.menuName === 'Sportsbook'){
      if(isLoggedIn || loggedIn){
        navigate(item.url)
      }else{
        openPopup()
      }
    }else{
      navigate(item.url)
    }
  }


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdown(false); // Close the dropdown if clicked outside
    }
  };

  const handleDropdownClicks = (name) => {
    if(name === 'profile'){
      navigate('/profile');
    }else if(name === 'change_password'){

    }else if(name === 'account_statement'){
      navigate('/myAccountStatment');
    }else if(name === 'pl_statement'){
      navigate('/MyPlStatement');
    }else if(name === 'my_bets'){
      navigate('/mybets');
    }else if(name === 'game_report'){
      navigate('/myGameReport');
    }
    setDropdown(false);
  }

  useEffect(() => {
    callGameReport();
    callWallet();
      const intervalId = setInterval(callGameReport, 2000);
      const intervalId2 = setInterval(callWallet, 2000);

      return () => {clearInterval(intervalId);clearInterval(intervalId2);}
  }, [availableBalance,totalExposure,isLoggedIn,loggedIn,location]);

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const callNotificationAPI = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
  
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/notification/getMyNotificationAPI`, requestOptions)
      .then((response) => response.text())
      .then((result) =>{
        // console.log(result)
        let resultdata = JSON.parse(result)
      setNotificationData(resultdata)}
    )
      .catch((error) => console.error(error));
  }

 const formatTimefromAPI = (isoDate) => {
  const date = new Date(isoDate);

  const optionsDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // This ensures the time is in 12-hour format with AM/PM
  };

  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  return `${formattedTime}`;
};

 const formatDatefromAPI = (isoDate) => {
  const date = new Date(isoDate);

  const optionsDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // This ensures the time is in 12-hour format with AM/PM
  };

  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  return `${formattedDate}`;
};

const handleItem = (item) => {
  setActiveTab(item.menuName);
  handleMenu(item);
}

  return (
    <>
      <header>
        <div className='container-fluid'>
          <div className='main-menu'>
            <div className="logo"> <Link to='/'> <img src={`https://admin.${domainName}/logo/${domainName}1.webp`} alt='' /> </Link> </div>
            {menuResponse && <div className="menu">
              <div className='menu-wrap'>
                {menuResponse && menuResponse.data.map((item, index) => (
                  <span className={`menu-links ${item.menuName === 'In-Play' ? 'flash-yellow' : ''} ${location.pathname === item.url ? 'active' : ''}`} onClick={()=>handleItem(item)} key={index}>
                  {item.menuName}
                  </span>
                  ))}
              </div>
            </div>}

            <div className='account-btns'>
            {isLoggedIn || loggedIn ?
            <>
              <button className='btn my-bet-btn' onClick={()=>navigate('/mybets')}>My Bets</button>
              <div className='wallet-bal'>
                <span className='bal'>{`Bal: ${availableBalance.toFixed(2)}`}</span> <span className='exp'>{`Exp: ${totalExposure.toFixed(2)}`}</span>
              </div>
            </>
            :''
            }

              {isLoggedIn || loggedIn ?
              <>
                <div className='dropdown'>
                  <button className="btn" type="button" onClick={dropdownHandle}> <img className="profile-img" src={userProfile} alt="" /> <div className="btn-header-username">{sessionData && sessionData?.userName}</div> <svg className="dpw-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path></svg> </button>
                  <div className={`dropdown-menu ${dropdown ? 'active' : ''}`} ref={dropdownRef}>
                    <ul className='dropdown-menu-list'>
                      <li> <span onClick={() => {handleShow("change_password"); handleDropdownClicks('change_password')}}>Change Password</span> </li>
                      <li><span onClick={() => {handleShow("one_click"); handleDropdownClicks('one_click_bet');}}>One Click Bet</span></li>
                      <li><span className={`${location.pathname === '/profile' ? 'active' : ''}`} onClick={() => handleDropdownClicks('profile')}>User Profile</span></li>
                      <li><span className={`${location.pathname === '/myAccountStatment' ? 'active' : ''}`} onClick={() => handleDropdownClicks('account_statement')}>Account Statement</span></li>
                      <li><span className={`${location.pathname.includes('/MyPlStatement')? 'active' : ''}`} onClick={() => handleDropdownClicks('pl_statement')}>P/L Statement</span></li>
                      <li><span className={`${location.pathname === '/mybets' ? 'active' : ''}`} onClick={() => handleDropdownClicks('my_bets')}>My Bets</span> </li>
                      <li><span className={`${location.pathname.includes('/myGameReport')? 'active' : ''}`} onClick={() => handleDropdownClicks('game_report')}>Game Report</span></li>
                      <li><span onClick={() => {handleShow('stack_change'); handleDropdownClicks('set_button_stake')}}>Set Button Stake</span></li>
                    </ul>
                    <button className='btn logout-btn' onClick={()=> logoutHandle(true)}>Logout</button>
                  </div>
                </div>
                {/* {checkLogin ? logoutHandle(false) :''} */}
              </>

               : <><button type='button' className='btn login-btn' onClick={openPopup}>Login</button></> }

            </div>
          </div>
          <div className='natification-bar'>
            <button onClick={()=> handleShow("notification")}><i className="fa-solid fa-bell"></i>Latest</button>
            <marquee>
            {notificationData?.notifications?.map((item, index)=>(
              <span key={index}>{item?.message}</span>
            ))}
            </marquee>
          </div>
        </div>
      </header>

      <Popup popupClass={`alertMsgpopup ${logout ? 'open' : ''}`} content={<div className={`alert-msg err`}><span className='msg'>Logout Successfully..</span><div className="alert-progress-bar"></div></div>} />

      <Modal show={notificationModal} onHide={handleClose} className='latest-notifiction-popup' dialogClassName="modal-dialog-centered v-center">
        <Modal.Header closeButton>
          <h2> <i className="fa-solid fa-bell"></i> Notification</h2>
        </Modal.Header>
        <Modal.Body>
          <div className="notification-list">
          {notificationData?.notifications?.map((item, index)=>(
                <div className="box" key={index}>
                            <div className="notification-header">{formatDatefromAPI(item.startDate)}</div>
                            <div className="notification-content">
                                <div className="date-titme">
                                    <p className="date">{formatDatefromAPI(item.startDate)}</p>
                                    <p className="time">{formatTimefromAPI(item.startDate)}</p>
                                </div>
                                <div className="conent">
                                    <p>{item?.message}</p>
                                </div>
                            </div>
                        </div>
              ))}

                    </div>

        </Modal.Body>
      </Modal>


      {isLoggedIn || loggedIn ? <OneClickPopups isopen={OneClickPopup} handleClose={handleClose} /> : ''}
      <ChangePasswordPopup isopen={changePassword} handleClose={handleClose} isPasswordChange={isPasswordChange} />
      {isLoggedIn || loggedIn ? <StackChnage isopen={stackChange} handleClose={handleClose}/>:''}

    </>
  )
}

export default Header