import React, { useContext, useEffect, useState } from 'react'
import useSocket from '../../Websoket.js';
import './matchdata.css'
import { PopupContext } from '../LoginPopup/LoginPopupContext.js';
import betslipclosearrow from '../../assets/images/betslip-close-arrow.png'
import ExchanngeinfoPopup from './ExchanngeinfoPopup.js';


function BookMaker({ eventAccess, getBookmaker, onBetClick }) {
    const bookMaker = {
        "eventId": eventAccess?.matchData?.eventId,
        "marketIds": [
            eventAccess?.bookmakerId
        ],
        "userId": "66604ec15be7e5de0f25ac4d"
    };
    const isOneClicked = sessionStorage.getItem('isOneClicked')
    let oneClickValue
    if(isOneClicked == 'true'){
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    }
    const [bookmaker, setBookmaker] = useState(bookMaker);
    const { isConnected, receivedBookmaker, receivedBookData,sendMessage, receivedcashoutBm } = useSocket();
    const [slideToggle, setSlideToggle] = useState(false)
    const [priceGet, setPriceGet] = useState(0);
    const [oddsValue, setoddsValue] = useState();
    const [bklyClassValue, setBklyClass] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const { openPopup , loggedIn, isLoggedOut,direactsecId, setSecIds,isBetUpdate,betNotUpdated,clearSecId,unsetBetLoader,isBetLoader, loaderCounter } = useContext(PopupContext);
    const max_odds = receivedBookmaker?.finalResult?.[0]?.max_odd
    const [plusMinusValue, setplusMinusValue] = useState();
    const [onetimeCall, setOnetimeCall] = useState(false);
    const [perticularprofit, setperticularprofit] = useState();
    const [grulesPopup, setGrulesPopup] = useState();
    const [Beton, setBeton] = useState();
    const [betLoader, setBetLoader] = useState(false);
    const escapeSelectorId = (id) => {
        if(id){
            return String(id).replace(/([#.,+*~':"!^$[\]()=>|\\/])/g, '\\$1');
        }
    };
    useEffect(() => {
        let intervalId;
        if (isConnected && bookmaker) {
            sendMessage('bookmakerMarketIds', bookmaker);
        intervalId = setInterval(() => {
            sendMessage('bookmakerMarketIds', bookmaker);
        }, 5000);

        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConnected, bookmaker, sendMessage,receivedBookmaker]);

    


    const gruleshandleClose = ()=>{
        setGrulesPopup(false);
    }
    const grulesHandle = ()=>{
        setGrulesPopup(true);
    }

    const toggleHandle = (slideId, bklyClass, event, runnerName) => {
        const strongElement = event.target.tagName === 'SPAN'
        ? event.target.querySelector('strong').innerText
        : event.target.closest('span')?.querySelector('strong').innerText;
        setSlideToggle(slideToggle === slideId ? slideId : slideId);
        setBklyClass(bklyClass);
        setoddsValue(strongElement);
        setSecIds(slideId);
        setPriceGet(0)
        setBeton(runnerName)
    }

    useEffect(() => {
        // Call cashOuthandle3 when receivedcashoutBm updates and is valid
        if (Object.keys(receivedcashoutBm).length > 0 && receivedcashoutBm.secId) {
            cashOuthandle3();
        }
    }, [receivedcashoutBm]); // Dependency array: this runs whenever receivedcashoutBm changes

    const toggleHandle2 = (secId, bklyClass, odds, Stake) => {
        setSlideToggle(slideToggle === secId ? secId : secId);
        setBklyClass(bklyClass);
        setoddsValue(odds);
        setPriceGet(Stake);
        setSecIds(secId);
    };

    const cashOuthandle3 = () => {
        let type = receivedcashoutBm.betType === "LAY" ? 'lay' : 'back';
        toggleHandle2(receivedcashoutBm.secId, type, receivedcashoutBm.odds, receivedcashoutBm.stake);
    };

    const handleCashOut = () => {
        let marketId = receivedBookmaker?.finalResult?.[0]?.marketId;
        let userinfo = sessionStorage.getItem('userinfo');
        let eventID = receivedBookmaker?.finalResult?.[0]?.eventId;
        setSlideToggle(false);
        if (userinfo && eventID) {
            userinfo = JSON.parse(userinfo);
            sendMessage('cashOOutBm', { id: marketId, user: userinfo, eventID });
        }
    };


    const addValue = (newValue,event) => {
        const closestBetslip = event.target.closest('.data-with-betslip');
        if (closestBetslip) {
            const bookValueDiv = closestBetslip.querySelector('.book-value');
            if (bookValueDiv) {
                // console.log('Found book-value:', bookValueDiv);
                let value
                if(bookValueDiv.innerText == ''){
                    if(bklyClassValue === 'back'){
                        value = (newValue * oddsValue)/100
                    }else{
                        value = -(newValue * oddsValue)/100
                    }
                }else{
                    let previousValue = bookValueDiv.innerText
                    if(bklyClassValue === 'back'){
                        value = parseFloat((newValue * oddsValue)/100) + parseFloat(previousValue)
                    }else{
                        value = parseFloat(previousValue) - parseFloat((newValue * oddsValue)/100)
                    }
                }
                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                setperticularprofit(value)
                if(value > 0){
                    bookValueDiv.classList.remove('red-txt')
                    bookValueDiv.classList.add('green-txt')
                }else{
                    bookValueDiv.classList.remove('green-txt')
                    bookValueDiv.classList.add('red-txt')
                }
                const closestBetslip2 = event.target.closest('.match-list');
                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                // console.log(allBookValueDivs, closestBetslip2);

                allBookValueDivs.forEach(div => {
                    if (div !== bookValueDiv) {
                        let value
                        if(div.innerText == ''){
                            if(bklyClassValue === 'back'){
                                value = -newValue
                            }else{
                                value = newValue
                            }
                        }else{
                            let previousValue = div.innerText
                            if(bklyClassValue === 'back'){
                                value = previousValue - newValue
                            }else{
                                value =  parseFloat(newValue) + parseFloat(previousValue)
                            }
                        }
                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                        if(value > 0){
                            div.classList.remove('red-txt')
                            div.classList.add('green-txt')
                        }else{
                            div.classList.remove('green-txt')
                            div.classList.add('red-txt')
                        }

                    }
                });
            } else {
                console.log('.book-value div not found');
            }
        } else {
            console.log('.data-with-betslip not found');
        }



        setplusMinusValue(newValue)
        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            setPriceGet(newValue);
        }

    };

    const handleSendMarketBookDetails = () => {
        let eventId =  eventAccess?.matchData?.eventId;
        let marketId = eventAccess?.bookmakerId;
        let userinfo = sessionStorage.getItem('userinfo');
        // console.log(receivedBookmaker, 'userinfouserinfouserinfo');
        if (userinfo && eventId && marketId) {

            userinfo = JSON.parse(userinfo);
            sendMessage('marketIdbookDetails', { eventId, marketId, user: userinfo });
        } else if(!userinfo){
            sendMessage('marketIdbookDetails', { eventId, marketId });
        }else{
            console.error('Missing eventId or marketId');
        }
    };

    useEffect(() => {
        setPreviousBookValues();
    }, [receivedBookData]);

    const setPreviousBookValues = () => {
        if (receivedBookData && receivedBookData.showData) {
            for (let i = 0; i < receivedBookData.showData.length; i++) {
                const originalId = receivedBookData.showData[i].runnerId;
                const escapedId = escapeSelectorId(originalId);
                const element = document.getElementById(`${escapedId}`);
                if (element) {
                    const mainDiv = element.querySelector('.book-value');
                    if (mainDiv) {
                        mainDiv.innerText = receivedBookData.showData[i].amount !== null && receivedBookData.showData[i].amount !== undefined &&  !isNaN(Number(receivedBookData.showData[i].amount)) ? Number(receivedBookData.showData[i].amount).toFixed(2) : 'Invalid data';
                        if (receivedBookData.showData[i].amount > 0) {
                            mainDiv.classList.remove('red-txt');
                            mainDiv.classList.add('green-txt');
                        } else {
                            mainDiv.classList.remove('green-txt');
                            mainDiv.classList.add('red-txt');
                        }
                    } else {
                        console.error(`.book-value not found for ID: #${escapedId}`);
                    }
                } else {
                    console.error(`Element with ID #${escapedId} not found. Original ID was: ${originalId}`);
                }
            }
            if(receivedBookData.showData.length == 0){
                // console.log('gothere');

                const escapedId = escapeSelectorId(receivedBookData.marketId);
                const element = document.getElementById(`${escapedId}`);
                // console.log(element, 'elementelement');

                if(element){
                   let div = element.querySelectorAll('.book-value')
                //    console.log(div, 'divdivdiv');

                   div.forEach(div => {
                        div.innerText = ''
                   })
                }
            }
        }
    };
    useEffect(() => {
        if (direactsecId) {
          setPreviousBookValues();
        }
      }, [direactsecId]);

    const updateBook = (event, oldvalue, newvalue) => {
        const closestBetslip = event.target.closest('.data-with-betslip');
        if (closestBetslip) {
            const bookValueDiv = closestBetslip.querySelector('.book-value');
            if (bookValueDiv) {
                if(oldvalue < newvalue){
                    newvalue = newvalue - oldvalue
                    let value
                if(bookValueDiv.innerText === ''){
                    if(bklyClassValue === 'back'){
                        value = (newvalue * oddsValue)/100
                    }else{
                        value = -((newvalue * oddsValue)/100)
                    }
                }else{
                    let previousValue = bookValueDiv.innerText
                    if(bklyClassValue === 'back'){
                        value = parseFloat((newvalue * oddsValue)/100) + parseFloat(previousValue)
                    }else{
                        value = previousValue - parseFloat((newvalue * oddsValue)/100)
                    }
                }
                bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                setperticularprofit(value)
                if(value > 0){
                    bookValueDiv.classList.remove('red-txt')
                    bookValueDiv.classList.add('green-txt')
                }else{
                    bookValueDiv.classList.remove('green-txt')
                    bookValueDiv.classList.add('red-txt')
                }
                const closestBetslip2 = event.target.closest('.match-list');
                const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                allBookValueDivs.forEach(div => {
                    if (div !== bookValueDiv) {
                        let value
                        if(div.innerText == ''){
                            if(bklyClassValue === 'back'){
                                value = -newvalue
                            }else{
                                value = newvalue
                            }
                        }else{
                            let previousValue = div.innerText
                            if(bklyClassValue === 'back'){
                                value = previousValue - newvalue
                            }else{
                                value =  parseFloat(newvalue) + parseFloat(previousValue)
                            }
                        }
                        div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                        if(value > 0){
                            div.classList.remove('red-txt')
                            div.classList.add('green-txt')
                        }else{
                            div.classList.remove('green-txt')
                            div.classList.add('red-txt')
                        }

                    }
                });
                }else{
                    let diff = Math.abs(newvalue - oldvalue)

                    const closestBetslip = event.target.closest('.data-with-betslip');
                    if (closestBetslip) {
                        const bookValueDiv = closestBetslip.querySelector('.book-value');
                        if (bookValueDiv) {
                            let value
                            if(bookValueDiv.innerText == ''){
                                if(bklyClassValue === 'back'){
                                    value = (newvalue * oddsValue)/100
                                }else{
                                    value = -((newvalue * oddsValue)/100)
                                }
                            }else{
                                let previousValue = bookValueDiv.innerText
                                if(bklyClassValue === 'back'){
                                    value = parseFloat(previousValue) - parseFloat((diff * oddsValue)/100)
                                }else{
                                    value = parseFloat(previousValue) + parseFloat((diff * oddsValue)/100)
                                }
                            }
                            bookValueDiv.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                            setperticularprofit(value)
                            if(value > 0){
                                bookValueDiv.classList.remove('red-txt')
                                bookValueDiv.classList.add('green-txt')
                            }else{
                                bookValueDiv.classList.remove('green-txt')
                                bookValueDiv.classList.add('red-txt')
                            }
                            const closestBetslip2 = event.target.closest('.match-list');
                            const allBookValueDivs = closestBetslip2.querySelectorAll('.book-value');
                            allBookValueDivs.forEach(div => {
                                if (div !== bookValueDiv) {
                                    let value
                                    if(div.innerText == ''){
                                        if(bklyClassValue === 'back'){
                                            value = -newvalue
                                        }else{
                                            value = newvalue
                                        }
                                    }else{
                                        let previousValue = div.innerText
                                        if(bklyClassValue === 'back'){
                                            value =  parseFloat(previousValue) + parseFloat(diff)
                                        }else{
                                            value =  parseFloat(previousValue) - parseFloat(diff)
                                        }
                                    }
                                    div.innerText = value !== null && value !== undefined && !isNaN(Number(value))
    ? Number(value).toFixed(2)
    : 'Invalid value';
                                    if(value > 0){
                                        div.classList.remove('red-txt')
                                        div.classList.add('green-txt')
                                    }else{
                                        div.classList.remove('green-txt')
                                        div.classList.add('red-txt')
                                    }

                                }
                            });
                        }
                    }
                }
            }
        }
    }


    const PlusValue = (event) => {
        const newValue =  plusMinusValue ? plusMinusValue : 100
        let oldValue = priceGet

        if (priceGet !== undefined) {
            setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
        } else {
            oldValue = 0
            setPriceGet(newValue);
        }
        let newValuestake =  parseFloat(oldValue) + parseFloat(newValue)

        updateBook(event, oldValue, newValuestake)
    };

    const subtractValue = (event) => {
        const valueToSubtract  =  plusMinusValue ? plusMinusValue : 100
        let oldValue = priceGet

        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            const newPrice = currentPrice - valueToSubtract;
            return newPrice < 0 ? 0 : newPrice;
        });
        if(oldValue){
            let newValue = oldValue - valueToSubtract
            if(newValue > 0){
                // console.log(newValue, oldValue);

                updateBook(event, oldValue, newValue)
            }else{
                setPreviousBookValues()
            }
        }
    };

    const incrementValue = (increment, event) => {
        let oldValue = priceGet
        setPriceGet(prevPrice => {
            const currentPrice = prevPrice ? Number(prevPrice) : 0;
            return currentPrice + increment;
        });
        let newValue = parseFloat(oldValue + increment)
        // console.log(oldValue, newValue);

        updateBook(event, oldValue, newValue)
    };

    const removeLastDigit = (event) => {
        let oldValue = priceGet
        setPriceGet(prevPrice => {
            if (prevPrice !== undefined) {
                const newPrice = String(prevPrice).slice(0, -1);
                return newPrice === '' ? 0 : Number(newPrice);
            }
            return 0;
        });
        if(oldValue){
            let newValue = String(oldValue).slice(0, -1);
            updateBook(event, oldValue, newValue)
        }else{
            setPreviousBookValues()
        }
    };





    // const onClickFunction = (runnerId, toggleclass, event, runnerName) =>{
    //     setSlideToggle(slideToggle === runnerId ? runnerId : runnerId);
    //     setSecIds(runnerId);
    //     setBklyClass(toggleclass);
    //     const strongElement = event.target.tagName === 'SPAN'
    //         ? event.target.querySelector('strong').innerText
    //         : event.target.closest('span')?.querySelector('strong').innerText;
    //         setoddsValue(strongElement);
    //         setPriceGet(oneClickValue)
    //         onBetClick({ eventid: receivedBookmaker?.finalResult?.[0]?.eventId, marketId: receivedBookmaker?.finalResult?.[0].marketId, stake: oneClickValue*1, bklyClassValue, selectionName: runnerName, secId: runnerId, oddsValue })
    // }

    const onClickFunction = (runnerId, toggleclass, event, runnerName) => {
        event.persist();
        setSlideToggle((prevSlideToggle) => prevSlideToggle === runnerId ? runnerId : runnerId);
        setSecIds(runnerId);
        setBklyClass(toggleclass);
        const strongElement = event.target.tagName === 'SPAN'
          ? event.target.querySelector('strong').innerText
          : event.target.closest('span')?.querySelector('strong')?.innerText;
        setoddsValue(strongElement);
        setPriceGet(oneClickValue);
        setBetLoader(true)
        onBetClick({
          eventid: receivedBookmaker?.finalResult?.[0]?.eventId,
          marketId: receivedBookmaker?.finalResult?.[0]?.marketId,
          stake: oneClickValue * 1,
          bklyClassValue: toggleclass,
          selectionName: runnerName,
          secId: runnerId,
          oddsValue: strongElement,
        });
      };
    useEffect(() => {
        let intervalId;
        if(isBetUpdate){
            handleSendMarketBookDetails();
            betNotUpdated();
        }

        const userinfo = sessionStorage.getItem('userinfo');
        if (userinfo) {
            if (isConnected) {
                let check = 0
                setInterval(() => {
                    if(check < 3){
                        handleSendMarketBookDetails();
                        check ++
                    }else{
                        clearInterval(intervalId);
                    }


                }, 1000);
            }

        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isConnected, sendMessage, isBetUpdate]);

    const getBetSlipClasss = (oddItem) => {
        // console.log(direactsecId , '-------',oddItem?.runnerId)
        // console.log(direactsecId === oddItem?.runnerId ? oddItem.status : '***********')
        if (direactsecId === oddItem?.runnerId) {
            if(oddItem.status !== 'OPEN'){
                clearSecId()
                unsetBetLoader();
                return 'betslip-wraper'
            }else{
                return 'betslip-wraper open'
            }
        }
        return 'betslip-wraper';
      };

    const handlecloseSlip =() => {
        try{
            setSlideToggle(false);
            setPriceGet(0);
            clearSecId();
            handleSendMarketBookDetails();
        }catch(err){
            console.log(err, 'errerrerr');
        }
    }

    useEffect(() => {
        if(!isBetLoader){
            handlecloseSlip();
        }
      }, [isBetLoader, loaderCounter]);

      function isSuspended(receivedMatchodds, getMatchOdds, index) {
        // Check the conditions for suspension
        // console.log(receivedMatchodds?.finalResult,"----------------------------------------------");
        console.log(getMatchOdds,"==============");

        if (Array.isArray(receivedMatchodds?.finalResult) && receivedMatchodds?.finalResult?.length > 0) {
            let data = receivedMatchodds?.finalResult?.[0] || null;
            if (data) {
                if (data.resumesuspendstatus) {
                    if(getMatchOdds !== null){
                        if (getMatchOdds?.status !== 'OPEN' || getMatchOdds?.runners?.[index]?.status !== 'OPEN') {
                            return false;
                        }else{
                            return true;
                        }
                    }else{
                        if (data?.runners?.[index]?.status !== 'ACTIVE'){
                            return false;
                        }else{
                            return true;
                        }
                    }
                } else {
                    return true;
                }
            }else{
                return true;
            }
        } else {
            return true;
        }
      }

    return (
        <>
            {eventAccess?.bookmakerStatus ? <div className='sport-all-data inner-match-list'>
                <div className='match-wrapper'>
                    <div className='heading'>
                        <div className='heading-text sport-name'>
                            Bookmaker <span className='rulespopup-btn' onClick={grulesHandle}><i className="fa-solid fa-circle-info"></i></span>
                        </div>
                        <div className='heading-text sport-name'>
                            Min : {receivedBookmaker?.finalResult?.[0]?.min_stake}, Max : {receivedBookmaker?.finalResult?.[0]?.max_stake}
                        </div>
                    </div>
                    <div className='heading match-heading'>
                        <div className='heading-text sport-name'>
                            Market
                        </div>
                        {(receivedBookData && receivedBookData.showData && receivedBookData.showData.length !== 0 && receivedBookmaker?.finalResult?.[0]?.runners.length === 2) ?
                            <div className='cashout-btn'>
                                <button onClick={handleCashOut} className='btn'>Cashout</button>
                            </div>
                            : null
                        }
                        <div className='odds-heading col2-odds-heading'>
                            <div className='heading-text box'>Back</div>
                            <div className='heading-text box'>Lay</div>
                        </div>
                    </div>
                    <div className='match-data'>
                        <div className='match-list' id={receivedBookmaker?.finalResult?.[0].marketId}>
                            {receivedBookmaker?.finalResult?.[0]?.runners.map((oddItem, index) => (
                                <div className='data-with-betslip' key={index} id={oddItem?.runnerId}>
                                    <div to='' className='match-info-row'>
                                        <div className='match-name'>{oddItem.runnerName}</div>
                                        <div className={`book-value`}></div>
                                        <div className='match-odds'>
                                        {isSuspended(receivedBookmaker,getBookmaker,index)&&  (
                                            <>
                                            <div className='suspended'>Suspended</div>
                                            {/* {clearSecId()}
                                            {unsetBetLoader()} */}
                                            </>
                                            )}
                                        
                                            <div className='box'>
                                                <span className={`blue ${max_odds < oddItem?.backPrices?.[0]?.price ? 'disable' : ''} ${oddItem?.backPrices?.[0]?.price !== 0 && getBookmaker?.runners?.[index]?.backPrices?.[0]?.price !== 0 ? '' : ' lock'}`} data-id={oddItem.runnerId} 
                                                onClick={(event) => { 
                                                    // if(!isBetLoader){
                                                        handleSendMarketBookDetails();
                                                    if (isOneClicked === "true") {
                                                        onClickFunction(oddItem.runnerId, 'back', event,oddItem.runnerName);
                                                    } else {
                                                        toggleHandle(oddItem.runnerId, 'back', event, oddItem.runnerName);
                                                    }
                                                    // }
                                                }}>
                                                {getBookmaker?.status !== 'OPEN' || getBookmaker?.runners?.[index]?.status !== 'OPEN' ? (
                                                    <>
                                                        <strong><i className="fa fa-lock"></i></strong>
                                                    </>
                                                    ) : (
                                                        oddItem?.backPrices?.[0]?.price !== 0 && getBookmaker?.runners?.[index]?.backPrices?.[0]?.price !== 0 ?
                                                <strong>{getBookmaker === null ? oddItem?.backPrices?.[0]?.price : getBookmaker?.runners?.[index]?.backPrices?.[0]?.price}</strong> : <i className="fa fa-lock"></i>
                                                    )}
                                                </span>
                                            </div>
                                            <div className='box'>
                                                <span className={`pink ${max_odds < oddItem?.layPrices?.[0]?.price ? 'disable' : ''} ${oddItem?.layPrices?.[0]?.price !== 0 && getBookmaker?.runners?.[index]?.layPrices?.[0]?.price !== 0 ? '' : ' lock'}`}
                                                    data-id={oddItem.runnerId} 
                                                    onClick={(event) => {
                                                        // if(!isBetLoader){
                                                        handleSendMarketBookDetails(); 
                                                        if (isOneClicked === "true") {
                                                            onClickFunction(oddItem.runnerId, 'lay', event,oddItem.runnerName);
                                                        } else {
                                                            toggleHandle(oddItem.runnerId, 'lay', event, oddItem.runnerName);
                                                        }
                                                        // }
                                                    }}>
                                                {getBookmaker?.status !== 'OPEN' || getBookmaker?.runners?.[index]?.status !== 'OPEN' ? (
                                                    <>
                                                        <strong><i className="fa fa-lock"></i></strong>
                                                    </>
                                                    ) : (
                                                        oddItem?.layPrices?.[0]?.price !== 0 && getBookmaker?.runners?.[index]?.layPrices?.[0]?.price !== 0 ?
                                                <strong>{getBookmaker === null ? oddItem?.layPrices?.[0]?.price : getBookmaker?.runners?.[index]?.layPrices?.[0]?.price}</strong> : <i className="fa fa-lock"></i>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={getBetSlipClasss(oddItem)}>
                                            <div className={`betslip ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>

                                                <div className="close-btn" onClick={()=>handlecloseSlip()}>×</div>
                                                <div className="betslip-top">
                                                    <div className="bet-selection-name" >Bet on : {Beton} @{oddsValue}</div>
                                                    <div className="bet-profit"> </div>
                                                    <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                                    <div className="betslip-stake">
                                                        <strong>Stake</strong>
                                                        <div className="input-group">
                                                            <span className="minus" onClick={(event) => subtractValue(event)}>-</span>
                                                            <input type="text" value={priceGet} onChange={(e) => setPriceGet(e.target.value)} className="set-stake-form-input" placeholder="00" aria-label="Last name"
                                                                autofocus="" maxLength="8" />
                                                            <span className="plus" onClick={(event) => PlusValue(event)}>+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="betslip-placebutton">
                                                    <div className="price-btns">
                                                        <span id="100" onClick={(event) => addValue('100',event)}>+100</span>
                                                        <span id="500" onClick={(event) => addValue('500',event)}>+500</span>
                                                        <span id="1000" onClick={(event) => addValue('1000',event)}>+1k</span>
                                                        <span id="10000" onClick={(event) => addValue('10000',event)}>+10k</span>
                                                        <span id="50000" onClick={(event) => addValue('50000',event)}>+50k</span>
                                                        <span id="100000" onClick={(event) => addValue('100000',event)}>+100k</span>
                                                        <span id="1000" className="MAX">MAX</span>
                                                    </div>
                                                    <div className="betplace-btn">
                                                    {loggedIn || isLoggedIn ? <button className="place-bet" onClick={(event) =>{
                                                        setBetLoader(true);
                                                        onBetClick({eventid : receivedBookmaker?.finalResult?.[0]?.eventId , marketId :receivedBookmaker?.finalResult?.[0].marketId,stake: priceGet,bklyClassValue, selectionName :oddItem.runnerName, secId :oddItem.runnerId, oddsValue})} }>Place Bet</button> :  <button onClick={openPopup} className="login-btn">Login to continue</button>
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`betslip_mobileview ${direactsecId === oddItem.runnerId ? 'active' : ''} ${bklyClassValue}`}>
                                                <div className="close-btn" onClick={()=>handlecloseSlip()}>×</div>
                                                <table className="eventdetails">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className='plus-minus-wraper'>
                                                                <div className='plus-minus'>
                                                                    <span>-</span>
                                                                    <input type="text" value={oddsValue} />
                                                                    <span>+</span>
                                                                </div>
                                                                <div className='plus-minus'>
                                                                    <span onClick={(event) => subtractValue(event)}>-</span>
                                                                    <input type="text" value={priceGet} />
                                                                    <span onClick={(event) => PlusValue(event)} >+</span>
                                                                </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="11" className='mbet-slip'>
                                                                <div className="mbet-slip-wraper">
                                                                    <div className="odds-value" onClick={(event) => addValue('100',event)} id="100">+100</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('500',event)} id="500">+500</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('1000',event)} id="1000">+1k</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('10000',event)} id="10000">+10k</div>
                                                                    <div className="odds-value" onClick={(event) => addValue('50000',event)} id="50000">+50k</div>
                                                                    <div className="odds-value max" onClick={(event) => setPriceGet('10000',event)} id="10000">MAX</div>
                                                                    <div className="odds-value all" onClick={(event) => setPriceGet('98941.80',event)} id="98941.80">ALL IN</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="numbering">
                                                            <td colspan="11">
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><span onClick={(event) => incrementValue('1',event)} id="1">1</span></td>
                                                                            <td><span onClick={(event) => incrementValue('2',event)} id="2">2</span></td>
                                                                            <td><span onClick={(event) => incrementValue('3',event)} id="3">3</span></td>
                                                                            <td><span onClick={(event) => incrementValue('4',event)} id="4">4</span></td>
                                                                            <td><span onClick={(event) => incrementValue('5',event)} id="5">5</span></td>
                                                                            <td><span onClick={(event) => incrementValue('6',event)} id="6">6</span></td>
                                                                            <td rowspan="2"><span className="back-span" onClick={(event) => removeLastDigit(event)}><img alt="" src={betslipclosearrow}/></span></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><span onClick={(event) => incrementValue('7',event)} id="7">7</span></td>
                                                                            <td><span onClick={(event) => incrementValue('8',event)} id="8">8</span></td>
                                                                            <td><span onClick={(event) => incrementValue('9',event)} id="9">9</span></td>
                                                                            <td><span onClick={(event) => incrementValue('0',event)} id="0">0</span></td>
                                                                            <td><span onClick={(event) => incrementValue('00',event)} id="double0">00</span></td>
                                                                            <td><span onClick={(event) => incrementValue('000',event)} id="dot">000</span></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        <tr className="bet-btns">
                                                            <td colspan="11">
                                                                <div className="btn-wraper">
                                                                    <button className="btn cancel-bet" onClick={handlecloseSlip}>CANCEL</button>
                                                                    {loggedIn || isLoggedIn ? <button className="btn place-bet" onClick={(event) =>{
                                                                        setBetLoader(true)
                                                                        onBetClick({eventid : receivedBookmaker?.finalResult?.[0]?.eventId , marketId :receivedBookmaker?.finalResult?.[0].marketId,stake: priceGet,bklyClassValue, selectionName :oddItem.runnerName, secId :oddItem.runnerId, oddsValue})
                                                                    }

                                                                        }>Place Bet</button> :  <button onClick={openPopup} className="btn place-bet">Login to continue</button>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                    {isBetLoader ?
                                       <div className="loader-sec loading">
                                        Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                                        </div> : ''}
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>

                </div>
            </div> : ''}
            <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
            </>

    )

}

export default BookMaker